import React, { Component } from 'react';
import {Element} from 'react-scroll';
import './App.css';
import AboutMe from '../components/AboutMe/AboutMe';
import Skills from '../components/Skills/Skills';
import Projects from '../components/Projects/Projects';
import Contact from '../components/Contact/Contact';

class App extends Component {

  render() {
    return (
      <div className="App">
        <Element name="about">
          <AboutMe />
        </Element>
        <Element name="skills">
          <Skills />
        </Element>
        <Element name="projects">
          <Projects />
        </Element>
        <Element name="contact">
          <Contact />
        </Element>
      </div>
    );
  }
}

export default App;
