import React, {Component} from 'react';
import _ from 'lodash';

import classes from './Skills.module.css';

const htmlOpen = '<html>'
const htmlClose = '</html>'
const css = '.css {...}'

class Skills extends Component {
  state = {
    cssShouldBound: true,
    shouldDisplayTooltip: true,
  };

  skillsDescription = {
    htmlTooltip: 'I understand well and utilize most of HTML tags to optimize the structure.',
    cssTooltip: "I've been working with flexbox, CSS grid, good with manipulating position if needed to implement tricky design.",
    reactTooltip: "I'm comfortable with React, working with components' life cycle and making decisions on state management. I've also been using Redux and Redux form.",
    nodeTooltip: "I used ExpressJS to deal with HTTP request and pass API data from node server to react client. I implemented authentication with JWT and passport strategies.",
    mongoDbTooltip: "I've created mongoose schema, stored collections data in mongoAtlas and retrieved the data."
  }

  componentDidMount() {
    this.handleTooltip();
    window.addEventListener('resize', _.debounce(this.handleTooltip, 500));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", _.debounce(this.handleTooltip, 500));
  }

  onToggleBouncing = () => {
    this.setState((prevState, props) => {return {cssShouldBound: !prevState.cssShouldBound}});
  }

  handleTooltip = () => {
    console.log(window.innerWidth);
    if (window.innerWidth <= 900) {
      this.setState({ shouldDisplayTooltip: false });
    } else {
      this.setState({ shouldDisplayTooltip: true });
    }
  }

  render() {
    const { cssShouldBound, shouldDisplayTooltip } = this.state;
    const { htmlTooltip, cssTooltip, reactTooltip, nodeTooltip, mongoDbTooltip } = this.skillsDescription;

    return (
      <section>
        <div className={`container ${classes.skills}`}>
          <h2 className="heading mb-5 mb-sm-4">Skills</h2>
          <div className="row">
            {/* HTML */}
            <div className="col-6 col-md-4 col-lg-3 align-self-end mb-0">
              <div
                data-tooltip={htmlTooltip}
                className={`d-flex align-items-center justify-content-center flex-column ${shouldDisplayTooltip ? 'skills-tooltip' : ''} ${classes.square}`}
              >
                <p className={`mb-0 ${classes.html_tag}`}>{htmlOpen}</p>
                <p className={`mb-0 ${classes.html_tag}`}>{htmlClose}</p>
              </div>
            </div>
            {/* CSS */}
            <div className={`col-6 col-md-4 col-lg-3 align-self-end mb-4 mb-md-0 ${classes.button_wrapper}`}>
              <div
                data-tooltip={cssTooltip}
                className={`${cssShouldBound ? classes.bouncing : ''} ${classes.circle} mx-lg-auto d-flex align-items-center justify-content-center ${shouldDisplayTooltip ? 'skills-tooltip' : ''}`}
              >
                <p className={`mb-0 ${classes.css_tag}`}>{css}</p>
              </div>
              <button type="button" className={classes.stop_button} onClick={this.onToggleBouncing}>{cssShouldBound ? 'Stop bouncing' : 'Let it bounce!'}</button>
            </div>
            {/* Stack */}
            <div className="col-md-4 col-lg-4 align-self-end mt-5 mt-md-0">
              <div
                data-tooltip={reactTooltip}
                className={`d-flex align-items-center ml-md-auto justify-content-md-end pl-4 pl-md-0 pr-0 pr-md-4 ${shouldDisplayTooltip ? 'skills-tooltip' : ''} ${classes.rectangle_react}`}
              >
                <p className="mb-0">React</p>
              </div>

              <div
                data-tooltip={nodeTooltip}
                className={`d-flex align-items-center ml-md-auto justify-content-md-end pl-4 pl-md-0 pr-0 pr-md-4 ${shouldDisplayTooltip ? 'skills-tooltip' : ''} ${classes.rectangle_node}`}
              >
                <p className="mb-0">nodeJS</p>
              </div>

              <div
                data-tooltip={mongoDbTooltip}
                className={`d-flex align-items-center ml-md-auto justify-content-md-end pl-4 pl-md-0 pr-0 pr-md-4 ${shouldDisplayTooltip ? 'skills-tooltip' : ''} ${classes.rectangle_mongo}`}
              >
                <p className="mb-0">MongoDB</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Skills;
