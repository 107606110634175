import React from 'react';

const ArrowDown = () => (
  <svg
    className="svg-arrow"
    x="0px"
    y="0px"
	  viewBox="0 0 21.825 21.825"
    style={{enableBackground: "new 0 0 21.825 21.825"}}
    xmlSpace="preserve"
  >
    <path d="M16.791,13.254c0.444-0.444,1.143-0.444,1.587,0c0.429,0.444,0.429,1.143,0,1.587l-6.65,6.651
    c-0.206,0.206-0.492,0.333-0.809,0.333c-0.317,0-0.603-0.127-0.81-0.333l-6.65-6.651c-0.444-0.444-0.444-1.143,0-1.587
    s1.143-0.444,1.587,0l4.746,4.762V1.111C9.791,0.492,10.299,0,10.918,0c0.619,0,1.111,0.492,1.111,1.111v16.904L16.791,13.254z"/>
  </svg>
);

export default ArrowDown;
