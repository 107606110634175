import React from 'react';

import Project from './Project/Project';
import DigiImage from '../../assets/images/Digipartnership.png';
import DanielImage from '../../assets/images/Daniel Rahman.png';
import IntegrifyImage from '../../assets/images/Integrify.png';
import classes from './Projects.module.css';

const Projects = () => (
  <section className={classes.project_section}>
    <div className="container">
      <h2 className="mb-5 heading">Projects</h2>
    </div>

    <Project
      imgSrc={DanielImage}
      imgAlt="Daniel Rahman website"
      title="Daniel Rahman website"
      linkSrc="https://www.danielrahman.com/"
      used="Gatsby, ReactJS, GraphQL, Storybook, Contentful, HTML5, Sass"
      description="This website is made as one of the media tools for elective campaign of Mr. Daniel Rahman, featuring his background information and opinion expressed through blog posts. The blog content needs to be updated dynamically, hence Contentful has been used. The brief came within a week before the website had to be up and running. As one of the three-member development team in this tight-schedule project, I have built several reuseable components and implemented social media SDK. After launch, I was also in charge of maintenance and customization according to feedbacks."
    />

    <Project
      imgSrc={IntegrifyImage}
      imgAlt="Integrify website"
      title="Integrify website"
      linkSrc="https://www.integrify.io/"
      used="Gatsby, ReactJS, Flow, Jest, GraphQL, Storybook, Contentful, HTML5, Sass"
      description="Integrify needs a new website to accommodate the rapid growth. This is my first project working in a multi-national team. As one of three front-end developers taking part in, my responsibility lies heavily in building and writing tests for many isolated components to maximize reusability. I also use Storybook for automatically documented design system."
      alignRight={true}
    />

    <Project
      imgSrc={DigiImage}
      imgAlt="Digipartnership website"
      title="Digipartnership website"
      linkSrc="https://digipartnerschaft.fi/"
      used="WordPress, Twig, Timber, Bedrock, HTML5, CSS3, jQuery"
      description="This is the freelance project ordered by Pixels Helsinki Oy. The content was optimized to be as flexible as possible while ensuring the layout can adapt to content change. I used Advanced Custom Fields plugin to provide better content structure and admin interface. The website also uses Polylang to support multi-language."
    />

  </section>
)

export default Projects;