import React from 'react';
import { string } from 'prop-types';

import classes from './Project.module.css';

const Project = ({ imgSrc, imgAlt, title, linkSrc, used, description, alignRight }) => {
  const classesAlignLeft = 'col-md-12 col-lg-8 mb-5';
  const classesAlignRight = 'col-md-12 col-lg-8 offset-lg-4 mb-5';

  return (
    <div className="container">
      <div className="row">
        <div className={alignRight ? classesAlignRight : classesAlignLeft}>
          {/* Image */}
          <div className="mb-4">
            <img className="img-fluid" src={imgSrc} alt={imgAlt}/>
          </div>
          {/* Title */}
          <h3 className={`heading mb-1 ${classes.project_title}`}>{title}</h3>
          {/* Link */}
          <p>
            <a className={`link ${classes.project_link}`} href={linkSrc} target="blank">{linkSrc}</a>
          </p>
          {/* Used languages/framework */}
          <p style={{fontStyle: 'italic'}}>
            {used}
          </p>
          {/* Description */}
          <p>
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}

Project.propTypes = {
  imgSrc: string.isRequired,
  imgAlt: string.isRequired,
  title: string.isRequired,
  linkSrc: string.isRequired,
  used: string.isRequired,
  description: string.isRequired,
}

Project.defaultProps = {
  alignRight: false,
}

export default Project;
