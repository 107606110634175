import React from 'react';
import Scroll from 'react-scroll';

import classes from './NavItems.module.css';

const NavItems = () => {

  const handleScroll = (elementName) => {
    Scroll.scroller.scrollTo(elementName, {
      duration: 800,
      delay: 100,
      smooth: true,
      offset: -50
    })
  }

  return (
    <ul className={`navbar-nav flex-row pt-3 ${classes.nav_items}`}>
      <li className="nav-item pr-2 pr-sm-3 pr-md-4">
        <a className="nav-link link d-inline-block" href="#about" onClick={(e) => { e.preventDefault(); handleScroll('about')}}>
          About me
      </a>
        <span className="separator pl-2 pl-sm-3 pl-md-4">|</span>
      </li>
      <li className="nav-item pr-2 pr-sm-3 pr-md-4">
        <a className="nav-link link d-inline-block" href="#skills" onClick={(e) => { e.preventDefault(); handleScroll('skills') }}>
          Skills
      </a>
        <span className="separator pl-2 pl-sm-3 pl-md-4">|</span>
      </li>
      <li className="nav-item pr-2 pr-sm-3 pr-md-4">
        <a className="nav-link link d-inline-block" href="#projects" onClick={(e) => { e.preventDefault(); handleScroll('projects') }}>
          Projects
      </a>
        <span className="separator pl-2 pl-sm-3 pl-md-4">|</span>
      </li>
      <li className="nav-item">
        <a className="nav-link link d-inline-block" href="#contact" onClick={(e) => { e.preventDefault(); handleScroll('contact') }}>Contact</a>
      </li>
    </ul>
  )
};

export default NavItems;