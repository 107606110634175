import React from 'react';
import Scroll from 'react-scroll';

import classes from './AboutMe.module.css';
import NavItems from '../NavItems/NavItems';
import LinkedinIcon from '../Icons/LinkedinIcon/LinkedinIcon';
import GithubIcon from '../Icons/GithubIcon/GithubIcon';
import ArrowDown from '../Icons/ArrowDown/ArrowDown';

const AboutMe = () => {
  const handleScroll = (elementName) => {
    Scroll.scroller.scrollTo(elementName, {
      duration: 800,
      delay: 100,
      smooth: true,
      offset: -50
    })
  }

  return (
    <section className="section-divider">
      <div className={classes.bg_light_blue}>
        <div className={`${classes.arrow_wrap} container`}>
          <div className={classes.about_arrow_down}>
            <a href="#skills" onClick={(e) => { e.preventDefault(); handleScroll('skills')}}><ArrowDown /></a>
          </div>
          <NavItems />

          <div className={classes.about}>
            <h1 className={`page-heading ${classes.aboutMe_title}`}>Kiet Nguyen</h1>
            <div>
              <a
                className="ml-1"
                href="https://www.linkedin.com/in/anhkiet-nguyen/"
                target="blank"
              >
                <LinkedinIcon />
              </a>

              <a
                className="ml-3"
                href="https://github.com/Kiet-Nguyen"
                target="blank"
              >
                <GithubIcon />
              </a>
            </div>

          </div>

          <div className={classes.about_content}>
            <p>
              I enjoy building websites and applications. My favorite language is Javascript.
            </p>
            <p className="mb-0">
              As much as I like coding, I love sharing my knowledge with people, reading tech articles and learning more about design.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutMe;