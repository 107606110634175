import React from 'react';
import Scroll from 'react-scroll';

import classes from './Contact.module.css';
import ArrowDown from '../Icons/ArrowDown/ArrowDown';
import LinkedinIcon from '../Icons/LinkedinIcon/LinkedinIcon';
import GithubIcon from '../Icons/GithubIcon/GithubIcon';

const Contact = () => {
  const handleScroll = (elementName) => {
    Scroll.scroller.scrollTo(elementName, {
      duration: 800,
      delay: 100,
      smooth: true,
      offset: -50
    })
  }

  return (
    <section>
      <div className={classes.bg_light_blue}>
        <div className={`${classes.arrow_wrap} container`}>
          <div className={classes.contact_arrow_up}>
            <a href="#about" onClick={(e) => { e.preventDefault(); handleScroll('about') }}><ArrowDown /></a>
          </div>

          <h2 className={`page-heading pt-5 ${classes.contact_title}`}>Contact me</h2>

          <div className={`${classes.contact_content} pb-3`}>
            <h3 className={`heading mt-5 mr-4 ${classes.contact_content_name}`}>Kiet Nguyen</h3>
            <p className={`mb-0 ${classes.contact_email}`}>
              <a className="link text-decoration-none" href="mailto:nguyenhoanganhkiet@gmail.com">nguyenhoanganhkiet@gmail.com</a>
            </p>
            <div className="pb-5">
              <a
                className="ml-1"
                href="https://www.linkedin.com/in/anhkiet-nguyen/"
                target="blank"
              >
                <LinkedinIcon />
              </a>

              <a
                className="ml-3"
                href="https://github.com/Kiet-Nguyen"
                target="blank"
              >
                <GithubIcon />
              </a>
            </div>
          </div>
          <p className="text-gray py-2 mb-0">{`© ${new Date().getFullYear()} by Kiet Nguyen.`}</p>
        </div>
      </div>
    </section>
  )
}

export default Contact;